import React, { useRef } from 'react';
import logo from './logo.png';
import it1 from './it1.jpg';
import it2 from './it2.jpg';
import it3 from './it3.jpg';
import ceo from './ceo.jpg';
import logoSingle from './logo-single.png';
import './App.css';
import {Image} from "primereact/image";
import {MenuItem} from "primereact/menuitem";
import {Menubar} from "primereact/menubar";
import {Card} from 'primereact/card';
import {Divider} from 'primereact/divider';
import {useFormik} from 'formik';
import {InputText} from "primereact/inputtext";
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import {InputTextarea} from "primereact/inputtextarea";
import emailjs from '@emailjs/browser';
import {Toast} from 'primereact/toast';

function App() {
    const form = useRef(null);
    const toast = useRef(null);
    const items: MenuItem[] = [
        {label: 'Home', icon: 'pi pi-fw pi-home', url: '#home'},
        {label: 'Training', icon: 'pi pi-fw pi-book', url: '#training'},
        {label: 'Clients', icon: 'pi pi-fw pi-users', url: '#clients'},
        {label: 'Contact', icon: 'pi pi-fw pi-comment', url: '#contact'},
        {label: 'About', icon: 'pi pi-fw pi-info', url: '#about'}
    ];

    const send = (data: { name: string, email: string, phone: string, message: string }) => {
        if (form.current) {
            emailjs.sendForm('service_c39cdp5', 'template_346yp1f', form.current, 'H-BWp1C9AH4vUrkPe')
                .then((result) => {
                    if (toast.current) {
                        // @ts-ignore
                        toast.current.show({severity: 'info', summary: 'Info', detail: 'Message Sent'});
                        formik.resetForm();
                    }
                }, (error) => {
                    // @ts-ignore
                    toast.current.show({severity: 'error', summary: 'Error', detail: 'There was a problem sending the message'});
                });
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: ''
        },
        onSubmit: (data: { message: string, phone: string, email: string, name: string }) => {
            data && send(data);
        }
    });

    return (
        <>
            <Card className="main" footer="© 2022-2023, Near IT Consulting. All rights reserved.">
                <div className="header" id="home">
                    <div className="logo">
                        <Image id="logo" src={logo} alt="Near IT Logo"/>
                        <Image id="logo-single" src={logoSingle} alt="Near IT Logo"/>
                    </div>
                    <div className="menu">
                        <Menubar model={items}/>
                    </div>
                </div>
                <div className="body">
                    <p id="#home">
                        We are not an ordinary technology consultancy, when they think of something like that, they
                        always have in mind the work following a pattern. However, Near IT aims to perform activities
                        outside the box, helping companies and users to achieve their goal.
                    </p>
                    <div className="it-images">
                        <Image src={it1} alt="Near IT #1"/>
                    </div>
                    <p>
                        If you've been tired of being denied a solution request, or worse, when they charge an abusive
                        amount, you can stop looking, because you've found the right consultancy for your problem,
                        because we always have a solution to your problem and charging a price fair.
                    </p>
                    <div className="it-images">
                        <Image src={it3} alt="Near IT #3"/>
                    </div>
                    <p id="contact-link">
                        Get in touch with us and we will be delighted to assist you by clicking on the
                        <a href="#contact">&nbsp;Contact&nbsp;</a>link.
                    </p>
                    <Divider/>
                    <p id="training">
                        Now let's talk to you, enthusiast or future programmer, or even you who are dissatisfied with the
                        current profession and want to come and change, here is the right place, because Near IT is putting
                        together a complete training for you, from zero to able to program.
                    </p>
                    <div className="it-images">
                        <Image src={it2} alt="Near IT #2"/>
                    </div>
                    <p id="training-link">
                        If you want to enter our world, you can start your training now to work with Near IT, when you
                        finish it, just click on the
                        <a href="http://training.nearit.com.br" target="_blank" rel="noreferrer">&nbsp;Training&nbsp;</a>
                        link.
                    </p>
                    <Divider/>
                    <p id="clients">
                        For the time being, we will keep the customer list anonymous to ensure our commitment to secrecy.
                    </p>
                    <Divider/>
                    <p id="contact">
                        If you are interested in talking to us, please do not hesitate to do so, just fill in the form
                        below and we will contact you shortly.
                    </p>
                    <Panel className="form">
                        <form onSubmit={formik.handleSubmit} ref={form}>
                            <span className="p-float-label">
                                <InputText id="name" name="name" value={formik.values.name} type="text"
                                    onChange={(e) => {
                                        formik.setFieldValue('name', e.target.value);
                                    }}
                                />
                                <label htmlFor="input_name">Name</label>
                            </span>
                            <span className="p-float-label">
                                <InputText id="email" name="email" value={formik.values.email} type="email"
                                           onChange={(e) => {
                                               formik.setFieldValue('email', e.target.value);
                                           }}
                                />
                                <label htmlFor="input_email">Email</label>
                            </span>
                            <span className="p-float-label">
                                <InputText id="phone" name="phone" value={formik.values.phone} type="tel"
                                           onChange={(e) => {
                                               formik.setFieldValue('phone', e.target.value);
                                           }}
                                />
                                <label htmlFor="input_email">Phone +0 (000) 000000000</label>
                            </span>
                            <span className="p-float-label">
                                <InputTextarea id="message" name="message" value={formik.values.message} rows={5} autoResize
                                               onChange={(e) => {
                                                   formik.setFieldValue('message', e.target.value);
                                               }}
                                />
                                <label htmlFor="input_message">Your message</label>
                            </span>
                            <div id="submit">
                                <Toast ref={toast}/>
                                <Button type="submit" label="Submit" />
                            </div>
                        </form>
                    </Panel>
                    <Divider/>
                    <p id="about">
                        The consulting was born with the support of CEO João Nuno Quintino, born in Portugal, but currently
                        living in Brazil, where he has been working to solve all the problems of companies together with
                        his associates, thus composing a team capable and ready to act in whatever comes.
                    </p>
                    <div className="ceo-images">
                        <Image src={ceo} alt="Near IT CEO"/>
                        <div>
                            <strong><h3>João Nuno Quintino</h3></strong>
                            <span><strong>Email:</strong> <a href="mailto:quintino@nearit.com.br">quintino@nearit.com.br</a></span>
                            <span><strong>Phone:</strong> +55 71 992 796 487</span>
                        </div>
                    </div>
                    <p>
                        Currently we are working in several languages, but the main ones would be: Java, .NET, Ruby, PHP
                        and Python. However, if any other need arises, we are ready to help.
                    </p>
                </div>
            </Card>
        </>
    );
}

export default App;
